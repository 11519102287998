import type { MetaDescriptor, MetaFunction } from '@remix-run/node'

// Copied from https://gist.github.com/ryanflorence/ec1849c6d690cfbffcb408ecd633e069
// Reference by remix docs https://remix.run/docs/en/main/route/meta#meta-merging-helper
// This merges meta from parent routes with current route and allows overriding
export const mergeMeta = <T>(
  overrideFn: MetaFunction<T>,
  appendFn?: MetaFunction<T>
): MetaFunction<T> => {
  return (arg: any) => {
    // get meta from parent routes
    let mergedMeta = arg.matches.reduce((acc: any, match: any) => {
      return acc.concat(match.meta || [])
    }, [] as MetaDescriptor[])

    // replace any parent meta with the same name or property with the override
    let overrides = overrideFn(arg)
    for (let override of overrides) {
      let index = mergedMeta.findIndex(
        (meta: any) =>
          ('name' in meta &&
            'name' in override &&
            meta.name === override.name) ||
          ('property' in meta &&
            'property' in override &&
            meta.property === override.property) ||
          ('title' in meta && 'title' in override)
      )
      if (index !== -1) {
        mergedMeta.splice(index, 1, override)
      }
    }

    // append any additional meta
    if (appendFn) {
      mergedMeta = mergedMeta.concat(appendFn(arg))
    }

    return mergedMeta
  }
}
